import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import SpecialBanner from "./SpecialBanner"

const imgQuery = graphql`
  {
    mob: file(relativePath: { eq: "Banners/leto/mob.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    tab: file(relativePath: { eq: "Banners/leto/tab.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    desk: file(relativePath: { eq: "Banners/leto/desk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    large: file(relativePath: { eq: "Banners/leto/large.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

type Props = {
  logoInBanner?: string
  letoBannerText?: boolean
  orderNum?: string
}

const offer = (
  <>
    Специальная карта Халва — <br />
    всего одна карта <br />
    для всего
  </>
)

const subTitle = (
  <>
    Все преимущества классической ХАЛВЫ <br /> плюс уникальные условия на покупки в ТРК&nbsp;«ЛЕТО»!
    <br />
    Карта выдаётся только в Санкт-Петербурге
  </>
)

export default function Banner({ logoInBanner, letoBannerText, orderNum }: Props) {
  const imgData = useStaticQuery(imgQuery)

  return (
    <SpecialBanner
      imgData={imgData}
      title={offer}
      subTitle={subTitle}
      logoInBanner={logoInBanner}
      letoBannerText={letoBannerText}
      orderNum={orderNum}
    />
  )
}
