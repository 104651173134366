import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import NewFormContainer from "./NewFormContainer/NewFormContainer"

import * as styles from "./forms.module.scss"
import type { City } from "../FormikForm/fields/CityField"

const imgQuery = graphql`
  {
    img: file(relativePath: { eq: "Forms/leto.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

type Props = {
  title?: string
  default_city?: City
  orderNum?: string
}

export function LetoForm({ title, default_city, orderNum }: Props) {
  const { img } = useStaticQuery(imgQuery)
  return (
    <NewFormContainer
      hasBirth
      default_city={default_city}
      imgData={img}
      title={title}
      sectionStyle={styles.letoSection}
      formStyle={styles.letoForm}
      orderNum={orderNum}
    />
  )
}
